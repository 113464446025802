.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.ol-control.info-control {
  padding: 0;
  bottom: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.info-control #info-control-header {
  padding: 10px;
  background-color: #fff7;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.info-control #info-control-header:hover {
  background-color: cyan;
  cursor: pointer;
}

.info-control #info-control-body {
  max-height: 50vh;
  visibility: visible;
  transition: all 0.2s;
  overflow-y: scroll;
}

.info-control #info-control-body.hidden {
  max-height: 0;
  visibility: hidden;
  transition: all 0.2s;
  padding: 0;
}
.info-control #info-control-body:hover {
  background-color: #fff9;
}

#info-control-header #header-title {
  flex-grow: 1;
}

#info-control-body a {
  text-decoration: none;
}
#info-control-body a div {
  padding: 5px 20px;
  width: 100%;
  transition: all 0.2s;
}
#info-control-body a div:hover {
  background-color: #ccc7;
  transform: scale(1.1);
}

#info-control-back {
  padding: 0px 10px;
}

#info-control-back:hover {
  background-color:rgba(0, 0, 0, 0.2)
}