.ol-popup {
  min-width: 280px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  bottom: 12px;
  left: -50px;
  box-shadow: 0 1px 4px #0003;
}

.ol-popup:after, .ol-popup:before {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid #0000;
  position: absolute;
  top: 100%;
}

.ol-popup:after {
  border-width: 10px;
  border-top-color: #fff;
  margin-left: -10px;
  left: 48px;
}

.ol-popup:before {
  border-width: 11px;
  border-top-color: #ccc;
  margin-left: -11px;
  left: 48px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.ol-control.info-control {
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0;
  bottom: 0;
}

.info-control #info-control-header {
  cursor: pointer;
  background-color: #fff7;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.info-control #info-control-header:hover {
  cursor: pointer;
  background-color: #0ff;
}

.info-control #info-control-body {
  max-height: 50vh;
  visibility: visible;
  transition: all .2s;
  overflow-y: scroll;
}

.info-control #info-control-body.hidden {
  max-height: 0;
  visibility: hidden;
  padding: 0;
  transition: all .2s;
}

.info-control #info-control-body:hover {
  background-color: #fff9;
}

#info-control-header #header-title {
  flex-grow: 1;
}

#info-control-body a {
  text-decoration: none;
}

#info-control-body a div {
  width: 100%;
  padding: 5px 20px;
  transition: all .2s;
}

#info-control-body a div:hover {
  background-color: #ccc7;
  transform: scale(1.1);
}

#info-control-back {
  padding: 0 10px;
}

#info-control-back:hover {
  background-color: #0003;
}

.layer-switcher {
  text-align: left;
  position: absolute;
  top: 3.5em;
  right: .5em;
}

.layer-switcher .panel {
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #eee;
  border-radius: 4px;
  margin: 0;
  display: none;
  overflow-y: auto;
}

.layer-switcher button {
  float: right;
  z-index: 1;
  width: 38px;
  height: 38px;
  color: #000;
  background-color: #fff;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: 2px;
  background-repeat: no-repeat;
  border: none;
}

.layer-switcher button:focus, .layer-switcher button:hover {
  background-color: #fff;
}

.layer-switcher.shown {
  overflow-y: hidden;
}

.layer-switcher.shown.ol-control, .layer-switcher.shown.ol-control:hover {
  background-color: #0000;
}

.layer-switcher.shown .panel {
  display: block;
}

.layer-switcher.shown button {
  display: none;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  background-image: unset;
  background-color: #eee;
  margin: 0 1px;
  display: block;
  position: absolute;
  right: 2px;
}

.layer-switcher.shown button:focus, .layer-switcher.shown button:hover {
  background-color: #fafafa;
}

.layer-switcher ul {
  margin: 1.6em .4em;
  padding-left: 0;
  list-style: none;
}

.layer-switcher ul ul {
  margin: .1em 0 0;
  padding-left: 1.2em;
}

.layer-switcher li.group + li.group {
  margin-top: .4em;
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  margin-top: .3em;
  position: relative;
}

.layer-switcher li input {
  height: 1em;
  width: 1em;
  font-size: 1em;
  position: absolute;
  left: 1.2em;
}

.layer-switcher li label {
  margin-top: 1px;
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
}

.layer-switcher label.disabled {
  opacity: .4;
}

.layer-switcher input {
  margin: 0;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  vertical-align: top;
  float: none;
  width: 1em;
  height: 1em;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  -ms-transition: -ms-transform .2s ease-in-out;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==");
  background-position: 50% 2px;
  margin: 0;
  font-size: 1em;
  transition: transform .2s ease-in-out;
  display: inline-block;
  position: absolute;
  left: 0;
}

.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  height: 0;
  overflow: hidden;
}

.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  border-right: 0;
  left: 0;
}

.ol-box {
  box-sizing: border-box;
  background-color: #fff6;
  border: 1.5px solid #b3c5db;
  border-radius: 2px;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  background: #003c884d;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-line-inner {
  color: #eee;
  text-align: center;
  will-change: contents, width;
  border: 1px solid #eee;
  border-top: none;
  margin: 1px;
  font-size: 10px;
  transition: all .25s;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  float: right;
  z-index: 10;
  background-color: #000;
}

.ol-scale-step-text {
  z-index: 11;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 12px;
  position: absolute;
  bottom: -5px;
}

.ol-scale-text {
  text-align: center;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  font-size: 14px;
  position: absolute;
  bottom: 25px;
}

.ol-scale-singlebar {
  height: 10px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
}

.ol-unsupported {
  display: none;
}

.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
}

.ol-viewport canvas {
  all: unset;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  background-color: #fff6;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
}

.ol-control:hover {
  background-color: #fff9;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  transition: opacity .25s linear, visibility linear;
  top: .5em;
  right: .5em;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ol-control button {
  color: #fff;
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  background-color: #003c8880;
  border: none;
  border-radius: 2px;
  margin: 1px;
  padding: 0;
  line-height: .4em;
  text-decoration: none;
  display: block;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  will-change: transform;
  font-size: 1.2em;
  font-weight: normal;
  display: block;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover, .ol-control button:focus {
  background-color: #003c88b3;
  text-decoration: none;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  max-width: calc(100% - 1.3em);
  flex-flow: row-reverse;
  align-items: center;
  display: flex;
  bottom: .5em;
  right: .5em;
}

.ol-attribution a {
  color: #003c88b3;
  text-decoration: none;
}

.ol-attribution ul {
  color: #000;
  text-shadow: 0 0 2px #fff;
  margin: 0;
  padding: 1px .5em;
  font-size: 12px;
}

.ol-attribution li {
  list-style: none;
  display: inline;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: #fffc;
}

.ol-attribution.ol-uncollapsible {
  border-radius: 4px 0 0;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-uncollapsible img {
  max-height: 1.6em;
  margin-top: -.2em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  height: 200px;
  top: 4.5em;
  left: .5em;
}

.ol-zoomslider button {
  height: 10px;
  position: relative;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: .5em;
  left: .5em;
}

.ol-overviewmap.ol-uncollapsible {
  border-radius: 0 4px 0 0;
  bottom: 0;
  left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  height: 150px;
  width: 150px;
  border: 1px solid #7b98bc;
  margin: 2px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: #fffc;
}

.ol-overviewmap-box {
  border: 2px dotted #003c88b3;
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

/*# sourceMappingURL=index.328b34f2.css.map */
